.app_box {
  background: #e4e4e4;
  min-height: 100vh;
}
.p_box {
  background: #fff;
  padding: 0 !important;
}
.p_box_header {
  background: #007041;
  font-size: 30px;
  color: #fff;
  padding: 20px;
  text-align: center;
}
.p_box_content {
  padding: 10px;
}
